/*
 * @Author: your name
 * @Date: 2022-04-01 14:30:15
 * @LastEditTime: 2022-04-07 17:22:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \syg_pc_web\src\api\agent_newApi.js
 */
import request from "@/api/request.js";

// 设备详情 音视频 按天查询
export function machine_details_video_day(data) {
  return request.post("/agent/machineDetailsvideoday", data);
}
//查询 音视频 按天查 带折线图
export function machine_details_video_day_pic(data) {
  return request.post("/agent/machineDetailsvideodaypic", data);
}

//设备详情 视力检测 按天查询
export function machine_details_eye_day(data) {
  return request.post("/agent/machineDetailseyeday", data);
}
//查询 视力检测 按天查 带折线图
export function machine_details_eye_day_pic(data) {
  return request.post("/agent/machineDetailseyedaypic", data);
}

//设备详情 验光仪 按天查询
export function machine_details_ygy_day(data) {
  return request.post("/agent/machineDetailsygyday", data);
}
//设备详情 验光仪 按天查询 带折线图
export function machine_details_ygy_day_pic(data) {
  return request.post("/agent/machineDetailsygydaypic", data);
}
//校园卫士 按天查询 点详情根据学校分组
export function machine_details_camera_day(data) {
  return request.post("/agent/machineDetailscameraday", data);
}
//查询 校园卫士 按天查 带折线图
export function machine_details_camera_day_pic(data) {
  return request.post("/agent/machineDetailscameradaypic", data);
}

//查询 校园卫士 某天所有设备
export function machine_details_camera_day_sc(data) {
  return request.post("/agent/machineDetailscameradaysc", data);
}
//查询音视频 按学校查
export function machine_details_video_school_day(data) {
  return request.post("/agent/machineDetailsvideoschoolday", data);
}

//查询视力检测按学校查
export function machine_details_eye_school_day(data) {
  return request.post("/agent/machineDetailseyeschoolday", data);
}

//查询校园卫士按学校查
export function machine_details_camera_school_day(data) {
  return request.post("/agent/machineDetailscameraschoolday", data);
}
//查询 验光仪 按学校查
export function machine_details_ygy_school_day(data) {
  return request.post("/agent/machineDetailsygyschoolday", data);
}
//代理设备详情状态 绑定时间 设备名称等
export function machine_details_all(data) {
  return request.post("/agent/machineDetailsAll", data);
}

//查询校园卫士 单设备详情 带折线图
export function machine_details_video_view(data) {
  return request.post("/agent/machineDetailsvideoview", data);
}

//删除学校
export function del_school(data) {
  return request.post("/agent/delSchool", data);
}

//管理后台新增学生眼睛列表数据
export function add_stu_eye(data) {
  return request.post("/school/stuEyeNew", data);
}

//学生视力数据导出word
export function excel_eye_word(data) {
  return request.post("/school/excelDaochueyeWord", data);
}